




















































































import debounce from 'lodash.debounce'
import { State } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
import { widgetSortReferences } from '../../@type/labratFinder'
import { baseType } from '../../store/types'
import ImageCarousel from '../../components/ImageCarousel.vue'
import LoadMoreBtn from '../../components/LoadMoreBtn.vue'

@Component({
  components: {
    ImageCarousel,
    LoadMoreBtn
  }
})
export default class BaseReferences extends Vue {
  @State(state => state.widget.references.items) items: boolean
  @State(state => state.widget.references.has_more) has_more: boolean
  @State(state => state.widget.total.references) total: number
  @State(state => state.query) query: string
  @State(state => state.baseReferences.sort) sort: string
  @State(state => state.baseReferences.loading) loading: string
  @State(state => state.widget.settings.references_options) settings
  @State(state => state.widget.widget_design) widget_design
  name = 'BaseReferences'

  widgetSortReferences = widgetSortReferences

  imageCarouselIndex = 0
  imageCarouselImages = []

  imageCarouselImagesShow(index, images) {
    console.info('imageCarouselImagesShow(', index, ', ', images, ')')
    this.imageCarouselIndex = index
    this.imageCarouselImages = images
  }

  get query_() {
    return this.query
  }

  set query_(query) {
    this.$store.commit('setQuery', query)
    this.fetchDebounce()
  }

  get sort_() {
    return this.sort
  }

  gen_style(params) {
    const style = {
      fontFamily: params['family'],
      fontSize: params['size'] + 'px'
    }
    if (params['weight'] == 1) {
      style.fontWeight = 'normal'
    }
    if (params['weight'] == 2) {
      style.fontStyle = 'italic'
    }
    if (params['weight'] == 3) {
      style.fontWeight = 'bold'
    }
    return style
  }

  get style_headline() {
    return this.gen_style(this.widget_design.headline)
  }

  get style_text() {
    return this.gen_style(this.widget_design.text)
  }


  set sort_(sort) {
    this.$store.commit('baseReferencesOffset', 0)
    this.$store.commit('baseReferencesSort', sort)
    this.fetchDebounce()
  }

  loadMore() {
    this.$store.commit('baseReferencesOffset', this.items.length)
    this.fetchDebounce()
  }

  fetchDebounce = debounce(this.fetch, 500)

  fetch() {
    this.$store.dispatch('toLoad', {
      baseType: baseType.BaseReferences
    })
  }

  mounted() {
    const headlineFontFamily = this.$store.state.widget.widget_design.headline['font-family'] ||
      this.$store.state.widget.widget_design.headline.family ||
      this.$store.state.widget.widget_design.headline.font
    const customFontHeadline = headlineFontFamily ? headlineFontFamily.replace(' ', '+') : null
    const textFontFamily = this.$store.state.widget.widget_design.text['font-family'] ||
      this.$store.state.widget.widget_design.text.family ||
      this.$store.state.widget.widget_design.text.font
    const customFontText = textFontFamily ? textFontFamily.replace(' ', '+') : null

    const stylesInHead =
      (customFontHeadline ? `@import url('https://fonts.googleapis.com/css?family=` + customFontHeadline + `'); ` : '') +
      (customFontText ? `@import url('https://fonts.googleapis.com/css?family=` + customFontText + `'); ` : '')

    const stylesInBody =
      (customFontHeadline ? `.labratfinder-widget-block-item__journal { font-family: '` + customFontHeadline + `' !important; } ` : '') +
      (customFontText ? ` .labratfinder-widget-block-item__title, .row { font-family: '` + customFontText + `' !important; } ` : '') +
      (this.$store.state.widget.widget_design.color ?
        `.active { background-color: #` + this.$store.state.widget.widget_design.color + ` !important; } ` : '')

    if (stylesInHead.length > 0) {
      const styleSheet1 = document.createElement('style')
      styleSheet1.type = 'text/css'
      styleSheet1.innerText = stylesInHead
      document.head.appendChild(styleSheet1)
    }

    if (stylesInBody.length > 0) {
      const styleSheet2 = document.createElement('style')
      styleSheet2.type = 'text/css'
      styleSheet2.innerText = stylesInBody
      this.$el.appendChild(styleSheet2)
    }
  }
}
