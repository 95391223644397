

















































































import debounce from 'lodash.debounce';
import {State} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import {baseType} from '../../store/types';
import {endUsersType, widgetSortEndUserScientist, widgetSortEndUserOrganization} from '../../@type/labratFinder';
import LoadMoreBtn from '../../components/LoadMoreBtn.vue'

@Component({
  components: {LoadMoreBtn}
})
export default class BaseEndUsers extends Vue {
  @State(state => state.widget.end_users_scientist) end_users_scientist: boolean;
  @State(state => state.widget.end_users_organization) end_users_organization: boolean;
  @State(state => state.widget.total.scientists) total_scientists: number;
  @State(state => state.widget.total.organizations) total_organizations: number;
  @State(state => state.baseEndUsers.sort) sort: string;
  @State(state => state.baseEndUsers.type) type: string;
  @State(state => state.baseEndUsers.loading) loading: string;
  @State(state => state.widget.settings.end_users_options) settings;
  @State(state => state.query) query: string;
  @State(state => state.widget.widget_design) widget_design;

  name = "BaseEndUsers";

  baseType = baseType;
  widgetSortEndUserScientist = widgetSortEndUserScientist;
  widgetSortEndUserOrganization = widgetSortEndUserOrganization;
  endUsersType = endUsersType;

  get total(): number {
    return this.total_organizations + this.total_scientists;
  }

  get type_() {
    return this.type;
  }

  set type_(type) {
    this.$store.commit('baseEndUsersOffset', 0);
    this.$store.commit('baseEndUsersType', type);
    this.$store.commit('baseEndUsersSort', 1);
    this.fetchDebounce();
  }

  get sort_() {
    return this.sort;
  }

  set sort_(sort) {
    this.$store.commit('baseEndUsersOffset', 0);
    this.$store.commit('baseEndUsersSort', sort);
    this.fetchDebounce();
  }

  get items() {
    switch (this.type) {
      case endUsersType.SCIENTIST:
        return this.end_users_scientist.items;
      case endUsersType.ORGANIZATION:
        return this.end_users_organization.items;
    }
  }

  get has_more() {
    switch (this.type) {
      case endUsersType.SCIENTIST:
        return this.end_users_scientist.has_more;
      case endUsersType.ORGANIZATION:
        return this.end_users_organization.has_more;
    }
  }

  loadMore() {
    this.$store.commit('baseEndUsersOffset', this.items.length);
    this.fetchDebounce();
  }

  fetchDebounce = debounce(this.fetch, 300);

  fetch() {
    let bt;
    switch (this.type) {
      case endUsersType.SCIENTIST:
        bt = baseType.BaseEndUsersScientists;
        break;
      case endUsersType.ORGANIZATION:
        bt = baseType.BaseEndUsersOrganizations;
        break;
    }
    this.$store.dispatch('toLoad', {
      baseType: bt,
    });
  }
}
