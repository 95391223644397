import { ApiValidator } from '@/api/index.d'
import * as lf from '@/@type/labratFinder'
import axios, { AxiosResponse } from 'axios'

class API implements ApiValidator {
  constructor() {
    axios.defaults.baseURL = (process.env['VUE_APP_BASE_URL'] || process.env['BASE_URL'] || '//api.labratfinder.com')
    axios.defaults.headers.common['Accept'] = 'application/json'
    axios.interceptors.response.use(
      r => {
        return r && r.data ? r.data : r
      },
      error => {
        console.error(error)
        return Promise.reject(error)
      })
    console.log('axios.defaults.baseURL = ', axios.defaults.baseURL)
  }

  widget(req: lf.widgetRequest): Promise<AxiosResponse<lf.widgetResponse>> {
    // const product = req.url ? req.url.split('.')[0].split('/')[2].replace('widget', '') : '2' //req.url.split('/')[3]
    return axios.post<lf.widgetResponse>('/widget', req)
  }
}

const api = new API()

export default api
