

























import {Carousel, Slide} from 'vue-carousel';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class ImageCarousel extends Vue {
  @Prop(Array) images;
  @Prop(Number) index;
  name = "ImageCarousel";

  displayPicker = false;

  get currentImage() {
    if (!this.images_[this.index_]) return false;
    return this.images_[this.index_];
  }

  get controlShow() {
    return this.images_.length > 1;
  }

  prewImage() {
    let index_ = this.index_;
    index_--;
    if (index_ < 0) {
      index_ = this.images.length-1;
    }
    this.index_ = index_;
  }

  nextImage() {
    let index_ = this.index_;
    index_++;
    if (index_ >= this.images.length) {
      index_ = 0;
    }
    this.index_ = index_;
  }

  get images_() {
    return this.images;
  }

  set images_(images) {
    return this.$emit('update:images', images);
  }

  get index_() {
    return this.index;
  }

  set index_(index) {
    return this.$emit('update:index', index);
  }

  @Watch('images_')
  onImagesUpdate(images) {
    if (images.length) {
      this.showPicker();
    }
  }

  showPicker() {
    //window.addEventListener('click', this.documentClick);
    this.displayPicker = true;
  }

  hidePicker() {
    //window.removeEventListener('click', this.documentClick);
    this.images_ = [];
    this.displayPicker = false;
  }

  togglePicker() {
    this.displayPicker ? this.hidePicker() : this.showPicker();
  }

  documentClick(e) {
    const el = this.$refs.modal;
    const target = e.target;
    if (el !== undefined && el !== target && !el.contains(target)) {
      this.hidePicker()
    }
  }
}
