







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component()
export default class LoadMoreBtn extends Vue {
  @Prop(Boolean) loading: boolean = false
  name: 'LoadMoreBtn'
}
