















export default {
  name: 'Spinner'
}
