export enum errorCodes {
  UNKNOWN_ERROR = 999,
  SIGNIN_USER_NOT_FOUND = 101,
  SIGNIN_PASWORD_NOT_MATCH = 102,
  REGISTER_USER_EXISTS = 201,
  REGISTER_BAD_EMAIL = 202,
  REGISTER_WEAK_PASSWORD = 203,
  RESET_PASSWORD_EMAIL_NOT_FOUND = 301,
}

export enum mailingListSortOrder {
  NAME = 1,
  CITATIONS = 2,
  DATE = 3
}

export enum notificationsFrequency {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3
}

export enum fontWeight {
  REGULAR = 1,
  ITALIC = 2,
  BOLD = 3
}

export enum endUsersType {
  SCIENTIST = 1,
  ORGANIZATION = 2
}

export enum filterEntityType {
  COUNTRY = 1,
  STATE = 2,
  CITY = 3,
  ZIP = 4
}

export enum sortOrder {
  ASC = 1,
  DESC = 2
}

export interface responseError {
  error_code: errorCodes;
  error_text: string;
}

export interface Response {
  success: boolean;
  result: any;
  error: responseError
}

export interface Plan {
  id: number;
  products: number;
  title: string;
  price: number;
  discount: number;
}

export interface UserInfo {
  id: number;
  token: string;
  email: string;
  plan: Plan;
}

export interface Product {
  id: number;
  enabled: boolean;
  title: string;
  aliases: string[];
  links: string[];
  scientists: {
    total: number;
    new: number;
  };
  organizations: {
    total: number;
    new: number;
  }
  references: {
    total: number;
    new: number;
  },
  content_settings?: ContentSettings
}

export interface ContentSettings {
  end_users_enabled: boolean;
  end_users_options: {
    scientists: boolean;
    organizations: boolean;
    sort: widgetSortEndUserScientist;
  }
  references_enabled: boolean;
  references_options: {
    images: boolean;
    sort: widgetSortReferences;
  }
}

export interface Settings {
  company: string;
  notification_frequency: notificationsFrequency
}

export interface Typography {
  family: string;
  size: number;
  weight: fontWeight;
}

export interface WidgetDesign {
  content?: ContentSettings;
  color: string;
  headline: Typography;
  text: Typography;
}

export interface Organization {
  id: number;
  title: string;
  address: string;
  scientists_count: number;
}

export interface Scientist {
  id: number;
  first_name: string;
  last_name: string;
  postal_address: string;
  email: string;
  is_new: boolean;
  cited_by: number;
  date: number;
  organizations_ids: [number];
}

export interface Reference {
  id: number;
  is_new: boolean;
  enabled: boolean;
  citations: number;
  date: number;
  title: string;
  url: string;
}

export interface FilterEntity {
  id: number;
  title: string;
  type: filterEntityType
}

export interface EndUsersListFilter {
  country: number | null;
  state: number | null;
  city: number | null;
  zip: number | null;
  new: boolean;
}

export interface signinRequest {
  email: string;
  password: string;
  remember: boolean;
}

export interface signinResponse extends Response {
  result: UserInfo
}

export interface logoutRequest {
}

export interface logoutResponse extends Response {
  result: boolean;
}

export interface registrationRequest {
  company: string;
  email: string;
  password: string;
  confirm: string;
}

export interface registrationResponse extends Response {
  result: UserInfo
}

export interface resetPasswordRequest {
  email: string;
}

export interface resetPasswordResponse extends Response {
  result: boolean;
}

export interface addProductRequest {
  title: string;
  aliases: string[];
  links: string[];
}

export interface addProductResponse extends Response {
  result: Product;
}

export interface editProductRequest extends addProductRequest {
  id: number;
}

export interface editProductResponse extends Response {
  result: Product;
}

export interface deleteProductRequest {
  id: number;
}

export interface deleteProductResponse extends Response {
  result: boolean;
}

export interface generateMailingListRequest {
  fields: {
    date: boolean;
    name: boolean;
    email: boolean;
    citations: boolean;
    mailing_address: boolean;
  };
  sort: mailingListSortOrder,
  ids: number[]
}

export interface generateMailingListResponse extends Response {
  result: boolean;
}

export interface contentSettingsRequest extends ContentSettings {
  product_id: number;
}

export interface contentSettingsResponse extends Response {
  result: boolean;
}

export interface plansRequest {

}

export interface plansResponse extends Response {
  result: Plan[];
}

export interface settingsRequest {
}

export interface saveSettingsRequest {
  company: string;
  notification_frequency: notificationsFrequency
}

export interface settingsResponse extends Response {
  result: Settings
}

export interface changePasswordRequest {
  old_password: string;
  new_password: string;
  confirm: string;
}

export interface changePasswordResponse extends Response {
  result: boolean;
}

export interface widgetDesignRequest {
}

export interface saveWidgetDesignRequest extends WidgetDesign {
}

export interface applyToAllWidgetDesignRequest extends WidgetDesign {
}

export interface widgetDesignResponse extends Response {
  result: WidgetDesign
}

export interface productsListRequest {
  query: string;
  sort: {
    field: string;
    order: sortOrder
  }
}

export interface productsListResponse extends Response {
  result: Product[] | null
}

export interface switchProductRequest {
  product_id: number;
}

export interface switchProductResponse extends Response {
  result: boolean;
}

export interface productRequest {
  id: number;
}

export interface productResponse extends Response {
  result: {
    product: Product;
    end_users_countries: FilterEntity[];
    end_users_scientists: productEndUsersScientistsListResponse;
    end_users_organizations: productEndUsersOrganizationsListResponse;
    references: productReferencesListResponse;
  };
}

export interface productFilterRequest {
  entity: filterEntityType;
  parent_id: number;
  query: string;
}

export interface productFilterResponse extends Response {
  result: {
    entity: filterEntityType;
    items: FilterEntity[];
    query: string;
  }
}

interface pagedRequest {
  product_id: number;
  sort: {
    field: string;
    order: sortOrder
  },
  page: number;
  items_per_page: number;
}

export interface productEndUsersScientistsListRequest extends pagedRequest {
  filter: EndUsersListFilter;
  query: string;
}

export interface productEndUsersScientistsListResponse extends Response {
  result: {
    items: Scientist[]
    total: number;
    page: number;
    items_per_page: number;
    type: endUsersType.SCIENTIST;
  }
}

export interface productEndUsersOrganizationsListRequest extends pagedRequest {
  filter: EndUsersListFilter;
  query: string;
}

export interface productEndUsersOrganizationsListResponse extends Response {
  result: {
    items: Organization[]
    total: number;
    page: number;
    items_per_page: number;
    type: endUsersType.ORGANIZATION;
  }
}

export interface productReferencesListRequest extends pagedRequest {
  query: string;
}

export interface productReferencesListResponse extends Response {
  result: {
    items: Reference[]
    total: number;
    page: number;
    items_per_page: number;
  }
}

export interface switchProductReferenceRequest {
  product_id: number;
  reference_id: number;
}

export interface switchProductReferenceResponse extends Response {
  result: boolean;
}

export interface organizationScientistsListRequest extends pagedRequest {
  organization_id: number;
}

export interface organizationScientistsListResponse extends Response {
  result: {
    items: Scientist[]
    total: number;
    page: number;
    items_per_page: number;
  }
}


export interface WidgetReferenceImage {
  id: number;
  thumbnail: string;
  url: string;
  title: string;
  description: string;
}

export enum widgetSortReferences {
  Date = 1,
  Citations = 2
}

export enum widgetSortEndUserScientist {
  Name = 1,
  CitedBy = 2
}

export enum widgetSortEndUserOrganization {
  Name = 1,
  NumberOfScientists = 2
}

export interface WidgetReference {
  id: number;
  citations: number;
  date: number;
  title: string;
  images: WidgetReferenceImage[];
  payed: boolean;
  url: string;
  journal: string;
}

export interface WidgetOrganization {
  id: number;
  title: string;
  scientists_count: number;
  url: string;
}

export interface WidgetScientist {
  id: number;
  first_name: string;
  last_name: string;
  cited_by: number;
  url: string;
}

export interface widgetRequest {
  url?: string; // если передан только url в ответе придут все поля
  user_id?: number;
  query?: string;
  references?: { // если передано в ответе придут только references
    sort: widgetSortReferences;
    offset: number;
  };
  end_users_scientist?: { // если передано в ответе придут только end_users
    sort: widgetSortEndUserScientist;
    offset: number;
  };
  end_users_organization?: { // если передано в ответе придут только end_users
    sort: widgetSortEndUserOrganization;
    offset: number;
  };
}

export interface widgetResponse extends Response {
  result: {
    settings?: ContentSettings;
    widget_design?: WidgetDesign;
    total?: {
      references: number;
      scientists: number;
      organizations: number;
    }
    references?: {
      items: WidgetReference[],
      has_more: boolean;
    },
    end_users_scientist?: {
      items: WidgetScientist[],
      has_more: boolean;
    },
    end_users_organization?: {
      items: WidgetOrganization[],
      has_more: boolean;
    }
  }
}
