


























import { State } from 'vuex-class'
import { Component, Prop, Vue } from 'vue-property-decorator'
import store from './store'
import './helpers/filters'

import BaseEndUsers from './components/EndUsers/BaseEndUsers.vue'
import BaseReferences from './components/References/BaseReferences.vue'
import Spinner from './components/Spinner.vue'

@Component({
  store,
  components: {
    BaseEndUsers,
    BaseReferences,
    Spinner
  }
})
export default class Widget extends Vue {
  @State('init') init: boolean
  @State('initError') initError: boolean | string
  @State('loading') loading: boolean
  @State(state => state.widget.settings) settings
  @State(state => state.widget.widget_design) widget_design
  @Prop(Number) userId: number

  name = 'App.vue'

  async created() {
    this.$store.dispatch('load', { url: window.location.href , user_id : this.userId });
  }
}
