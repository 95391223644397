import {
  ContentSettings,
  endUsersType,
  WidgetOrganization,
  WidgetReference,
  WidgetScientist, widgetSortReferences, widgetSortEndUserScientist, widgetSortEndUserOrganization, WidgetDesign
} from "@/@type/labratFinder";

export enum baseType {
  BaseEndUsersOrganizations = 1,
  BaseEndUsersScientists = 2,
  BaseReferences = 3,
}

export interface RootState {
  url: string | null;
  init: boolean;
  initError: boolean | string;
  loading: boolean;
  loadingError: boolean | string;
  widget: Widget,
  query: string,
  queryChanged: boolean;
  baseEndUsers: {
    loading: boolean,
    loadingError: boolean | string,
    sort: widgetSortEndUserScientist | widgetSortEndUserOrganization,
    offset: number,
    type: endUsersType,
  },
  baseReferences: {
    loading: boolean,
    loadingError: boolean | string,
    sort: widgetSortReferences,
    offset: number,
  },
}

export interface Widget {
  settings?: ContentSettings;
  widget_design?: WidgetDesign;
  total?: {
    references: number;
    scientists: number;
    organizations: number;
  }
  references?: {
    items: WidgetReference[],
    has_more: boolean;
  },
  end_users_scientist?: {
    items: WidgetScientist[],
    has_more: boolean;
    type: endUsersType.SCIENTIST;
  },
  end_users_organization?: {
    items: WidgetOrganization[],
    has_more: boolean;
    type: endUsersType.ORGANIZATION;
  }
}
