import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

import { baseType as baseTypeT, RootState, Widget } from '@/store/types'
import { endUsersType, widgetRequest, widgetResponse, widgetSortReferences } from '@/@type/labratFinder'
import api from '@/api/index'

const store: StoreOptions<RootState> = {
  state: {
    url: null,
    user_id: null,
    init: true,
    initError: false,
    loading: true,
    loadingError: false,
    widget: {},
    query: '',
    queryChanged: false,
    baseEndUsers: {
      loading: false,
      loadingError: false,
      sort: 2,
      offset: 0,
      type: endUsersType.SCIENTIST
    },
    baseReferences: {
      loading: false,
      loadingError: false,
      sort: widgetSortReferences.Citations,
      offset: 0
    }
  },
  mutations: {
    url(state, url) {
      state.url = url
    },
    user_id(state, user_id){
      state.user_id = user_id
    },
    init(state, [init, initError = false]) {
      if (init) {
        state.init = true
        state.initError = false
      } else {
        state.init = init
        state.initError = initError
        state.loading = false
      }
    },
    loading(state, [loading, loadingError = false]) {
      if (loading) {
        state.loading = true
        state.loadingError = false
      } else {
        state.loading = loading
        state.loadingError = loadingError
      }
    },
    widget(state, widget) {
      state.widget = widget
      if (widget.settings.end_users_enabled) {
        if (!widget.settings.end_users_options.scientists) {
          state.baseEndUsers.type = endUsersType.ORGANIZATION
        }
      }
      state.baseEndUsers.sort = widget.settings.end_users_options.sort
      state.baseReferences.sort = widget.settings.references_options.sort
      state.queryChanged = false
    },
    widgetUpdate(state, { baseType, widget }: { baseType: baseTypeT, widget: Widget }) {
      switch (baseType) {
        case baseTypeT.BaseReferences:
          if (state.widget.references === undefined) return
          if (widget.references === undefined) return
          if (state.baseReferences.offset) {
            state.widget.references.items = state.widget.references.items.concat(widget.references.items)
          } else {
            state.widget.references.items = widget.references.items
          }
          state.widget.references.has_more = widget.references.has_more
          break
        case baseTypeT.BaseEndUsersScientists:
          if (state.widget.end_users_scientist === undefined) return
          if (widget.end_users_scientist === undefined) return
          if (state.baseEndUsers.offset) {
            state.widget.end_users_scientist.items = state.widget.end_users_scientist.items.concat(widget.end_users_scientist.items)
          } else {
            state.widget.end_users_scientist.items = widget.end_users_scientist.items
          }
          state.widget.end_users_scientist.has_more = widget.end_users_scientist.has_more
          break
        case baseTypeT.BaseEndUsersOrganizations:
          if (state.widget.end_users_organization === undefined) return
          if (widget.end_users_organization === undefined) return
          if (state.baseEndUsers.offset) {
            state.widget.end_users_organization.items = state.widget.end_users_organization.items.concat(widget.end_users_organization.items)
          } else {
            state.widget.end_users_organization.items = widget.end_users_organization.items
          }
          state.widget.end_users_organization.has_more = widget.end_users_organization.has_more
          break
      }
    },
    baseReferencesOffset(state, offset) {
      state.baseReferences.offset = offset
    },
    baseReferencesSort(state, sort) {
      state.baseReferences.sort = sort
    },
    baseReferencesLoading(state, [value, error]) {
      state.baseReferences.loading = value
      state.baseReferences.loadingError = value ? false : error
    },
    setQuery(state, query) {
      state.query = query
      state.queryChanged = true
    },
    baseEndUsersOffset(state, offset) {
      state.baseEndUsers.offset = offset
    },
    baseEndUsersSort(state, sort) {
      state.baseEndUsers.sort = sort
    },
    baseEndUsersType(state, type) {
      state.baseEndUsers.type = type
    },
    baseEndUsersLoading(state, [value, error]) {
      state.baseEndUsers.loading = value
      state.baseEndUsers.loadingError = value ? false : error
    }
  },
  actions: {
    async load({ commit }, { url , user_id }) {
      commit('url', url)
      commit('user_id', user_id)
      commit('init', [true])

      const req: widgetRequest = {
        url,
        user_id
      }
      console.info('store/actions | load | req = ', req)
      const resp: widgetResponse = await api.widget(req)
      console.info('store/actions | load | resp = ', resp)
      if (!resp.success) {
        commit('init', [false, resp.error.error_text])
        return
      }
      commit('widget', resp.result)

      commit('init', [false])
    },
    async toLoad({ state, commit }, { baseType }: { baseType: baseTypeT }) {
      if (state.url === null) return

      const loadingMutation = state.queryChanged ? 'loading' :
        (baseType === baseTypeT.BaseReferences ? 'baseReferencesLoading' : 'baseEndUsersLoading')

      commit(loadingMutation, [true])

      const req: widgetRequest = {
        url: state.url,
        user_id: state.user_id,
        query: state.query
      }
      console.info('store/actions | toLoad | baseType = ', baseType)
      if (!state.queryChanged) {
        switch (baseType) {
          case baseTypeT.BaseReferences:
            req.references = {
              sort: state.baseReferences.sort,
              offset: state.baseReferences.offset
            }
            break
          case baseTypeT.BaseEndUsersScientists:
            req.end_users_scientist = {
              sort: state.baseEndUsers.sort,
              offset: state.baseEndUsers.offset
            }
            break
          case baseTypeT.BaseEndUsersOrganizations:
            req.end_users_organization = {
              sort: state.baseEndUsers.sort,
              offset: state.baseEndUsers.offset
            }
            break
        }
      } else {
        req.references = {
          sort: state.baseReferences.sort,
          offset: 0
        }
        req.end_users_scientist = {
          sort: state.baseEndUsers.sort,
          offset: 0
        }
        req.end_users_organization = {
          sort: state.baseEndUsers.sort,
          offset: 0
        }
      }
      console.info('store/actions | toLoad | req = ', req)
      const resp: widgetResponse = await api.widget(req)
      console.info('store/actions | toLoad | resp = ', resp)
      if (!resp.success) {
        commit(loadingMutation, [false, resp.error.error_text])
        return
      }
      if (!state.queryChanged) {
        commit('widgetUpdate', { baseType, widget: resp.result })
      } else {
        commit('widget', resp.result)
      }

      commit(loadingMutation, [false])
    }
  },
  getters: {}
}

Vue.use(Vuex)

export default new Vuex.Store<RootState>(store)
